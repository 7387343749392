<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Boards: "Tabuleiros",
      "BACK": "VOLTAR",
      "To start your board you need to donate to your sponsor.": "Para iniciar o seu tabuleiro você precisa realizar a doação para o seu patrocinador.",
      "Sponsor Information": "Informações do Patrocinador",
      "Donate": "Doar",
      "VIEW": "VER",
      "COMPLETED": "COMPLETO",
      "ENTER": "ENTRAR",
      "LOCKED": "BLOQUEADO",
      "Confirm Enter": "Confirmar Entrada",
      "Do you want to confirm the entrance to the board?": "Deseja confirmar a entrada no tabuleiro?",
    },
    es: {
      Boards: "Tableros",
      "BACK": "VOLVER",
      "To start your board you need to donate to your sponsor.": "Para comenzar su tablero, debe donar a su patrocinador.",
      "Sponsor Information": "Información de Patrocinador",
      "Donate": "Donar",
      "VIEW": "VER",
      "COMPLETED": "COMPLETO",
      "ENTER": "ENTRAR",
      "LOCKED": "BLOQUEADO",
      "Confirm Enter": "Confirmar Entrada",
      "Do you want to confirm the entrance to the board?": "Quiere confirmar la entrada al tablero?",
    },
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      statData: null,
      board: null,
      types: null,
      confirm: {
        modal: false,
        loading: false,
        type: null,
        time: 0,
      }
    };
  },
  methods: {
    getBoard() {
      this.statData = null;
      this.board = null;
      this.types = null;

      api.get("boards/" + this.$route.params.id).then((response) => {
        if (response.data.status == "success") {
          this.board = response.data.board;
          this.getStatData();

          this.types = response.data.types;
        } else {
          this.$router.push("/boards");
        }
      });
    },
    getStatData() {
      this.statData = [
        {
          title: "Code",
          value: this.board.id.split("-")[0],
        },
        {
          title: "Created in",
          value: this.board.date,
        },
        {
          title: "Donations Received",
          value: this.$options.filters.currency(this.board.received),
        },
      ];
    },
    confirmPosition(type) {
      this.confirm.modal = true;

      api
        .get("boards/positions/time")
        .then((response) => {
          if (response.data.status == "success") {
            this.confirm.type = type;
            this.confirm.time = response.data.time;
          }
        });
    },
    createPosition(type) {
      this.confirm.loading = true;

      api
        .post("boards/positions", {
          board: this.board.id,
          type: type,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$router.push("/boards/position/" + response.data.position.id);
          }
        });
    },
  },
  mounted() {
    this.getBoard();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex justify-content-between align-items-center"
        >
          <h4 class="mb-0 font-size-18">{{ t("Boards") }}</h4>
          <div class="ml-2">
            <router-link tag="a" :to="'/boards/'" class="btn btn-light btn-sm"
              >{{ t("BACK") }}</router-link
            >
            <button
              class="btn btn-light btn-sm ml-2"
              v-on:click="getBoard()"
              ><i class="lnr lnr-sync "></i></button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        v-for="stat of statData"
        :key="stat.icon"
        class="col-md-3 col-lg-3 col-xl-3"
      >
        <Stat
          :type="stat.type"
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value"
        />
      </div>
    </div>

    <div v-if="types" class="divisor mb-3">
      <span>{{ t("Boards") }}</span>
    </div>

    <div v-if="!types" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="dark"></b-spinner>
    </div>
    <div class="row" v-else-if="types && types.length > 0">
      <div
        class="col-md-3 mb-4"
        v-for="(row, index) in types"
        :key="index"
      >
        <template v-if="row.position.status == 'pending'">
          <router-link tag="a" :to="'/boards/position/' + row.position.id">
            <div class="card type m-0">
              <div
                class="card-body rounded text-center text-white"
                :class="row.id"
              >
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t("Donate") }} {{ row.donation | currency }}
                </div>
                <div
                  class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
                >
                  {{ t("VIEW") }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'active'">
          <router-link tag="a" :to="'/boards/position/' + row.position.id">
            <div class="card type m-0">
              <div
                class="card-body rounded text-center text-white"
                :class="row.id"
              >
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t("Donate") }} {{ row.donation | currency }}
                </div>
                <div
                  class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
                >
                  {{ t("VIEW") }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'completed'">
          <router-link tag="a" :to="'/boards/position/' + row.position.id">
            <div class="card type m-0">
              <div
                class="card-body rounded text-center text-white"
                :class="row.id"
              >
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t("Donate") }} {{ row.donation | currency }}
                </div>
                <div
                  class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
                >
                  {{ t("COMPLETED") }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'enter'">
          <button
            class="border-0 p-0 w-100 h-0 bg-transparent"
            v-on:click="confirmPosition(row.id)"
          >
            <div class="card type m-0">
              <div
                class="card-body rounded text-center text-white"
                :class="row.id"
              >
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t("Donate") }} {{ row.donation | currency }}
                </div>
                <div
                  class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
                >
                  {{ t("ENTER") }}
                </div>
              </div>
            </div>
          </button>
        </template>
        <template v-else-if="row.position.status == 'locked'">
          <div class="card type m-0">
            <div
              class="card-body rounded text-center text-white"
              :class="row.id"
            >
              <h4 class="m-0 text-white">{{ row.name }}</h4>
              <div class="type-donation">
                {{ t("Donate") }} {{ row.donation | currency }}
              </div>
              <div
                class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
              >
                {{ t("LOCKED") }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <b-modal v-model="confirm.modal" :title="t('Confirm Enter')" centered>
      <b-form
        v-if="confirm.type"
        @submit.prevent="createPosition(confirm.type)"
        class="text-center"
      >
        <div>
          <h5 class="mb-3">
            {{ t("Do you want to confirm the entrance to the board?") }}<br><br>
            <span class="text-danger">Após a entrada no tabuleiro, você terá um prazo de {{ confirm.time }} horas para realizar a sua doação.</span>
          </h5>
          <b-button
            :disabled="confirm.loading == true"
            type="submit"
            variant="default"
          >
            Confirmar
            <b-spinner
              v-if="confirm.loading"
              small
              class="ml-2 align-middle"
              variant="white"
              role="status"
            ></b-spinner>
          </b-button>
        </div>
      </b-form>
      <div v-else class="text-center">
        <b-spinner small class="ml-2 align-middle" variant="dark"></b-spinner>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>

<style scoped>
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}

.bronze {
  background: #cd7f32;
  background: linear-gradient(to bottom, #cd7f32 0%, #be7023 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #cd7f32),
    color-stop(100%, #be7023)
  );
  background: -webkit-linear-gradient(top, #cd7f32 0%, #be7023 100%);
  background: -moz-linear-gradient(top, #cd7f32 0%, #be7023 100%);
  background: -o-linear-gradient(top, #cd7f32 0%, #be7023 100%);
  background: -ms-linear-gradient(top, #cd7f32 0%, #be7023 100%);
}
.prata {
  background: #c0c0c0;
  background: linear-gradient(to bottom, #c0c0c0 0%, #b1b1b1 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #c0c0c0),
    color-stop(100%, #b1b1b1)
  );
  background: -webkit-linear-gradient(top, #c0c0c0 0%, #b1b1b1 100%);
  background: -moz-linear-gradient(top, #c0c0c0 0%, #b1b1b1 100%);
  background: -o-linear-gradient(top, #c0c0c0 0%, #b1b1b1 100%);
  background: -ms-linear-gradient(top, #c0c0c0 0%, #b1b1b1 100%);
}
.ouro {
  background: #d4af37;
  background: linear-gradient(to bottom, #d4af37 0%, #c5a028 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #d4af37),
    color-stop(100%, #c5a028)
  );
  background: -webkit-linear-gradient(top, #d4af37 0%, #c5a028 100%);
  background: -moz-linear-gradient(top, #d4af37 0%, #c5a028 100%);
  background: -o-linear-gradient(top, #d4af37 0%, #c5a028 100%);
  background: -ms-linear-gradient(top, #d4af37 0%, #c5a028 100%);
}
.diamante {
  background: #264348;
  background: linear-gradient(to bottom, #264348 0%, #173439 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #264348),
    color-stop(100%, #173439)
  );
  background: -webkit-linear-gradient(top, #264348 0%, #173439 100%);
  background: -moz-linear-gradient(top, #264348 0%, #173439 100%);
  background: -o-linear-gradient(top, #264348 0%, #173439 100%);
  background: -ms-linear-gradient(top, #264348 0%, #173439 100%);
}

.pending .card-body {
  filter: grayscale(1);
  opacity: 0.5;
}
</style>
